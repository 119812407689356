import React from "react"
import { Modal } from "react-bootstrap"
import WebriQForm from "@webriq/gatsby-webriq-form"

import ModalImg from "../../images/new-hero-Background.png"

class FormModal extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)

    this.state = {
      show: false,
    }
  }

  handleClose() {
    this.setState({ show: false })
  }

  handleShow(e) {
    this.setState({ show: true })
    e.preventDefault()
  }

  render() {
    const {
      btnClass,
      modalClass,
      modalSize,
      children,
      html,
      id,
      modalTitle,
    } = this.props
    return (
      <>
        <button
          aria-label="button"
          className="btn btn-primary btn-green w-100"
          onClick={this.handleShow}
        >
          Get Your Free App Audit Now
        </button>
        <Modal
          centered
          id={id}
          size="lg"
          className={modalClass}
          show={this.state.show}
          onHide={this.handleClose}
        >
          <Modal.Body>
            <div
              className="w-100"
              style={{
                background: "transparent",
                top: 0,
                left: 0,
              }}
            >
              <div className="position-relative">
                <div>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12 align-self-center mx-auto">
                        <div className="py-3 bg-white rounded">
                          <div className="row">
                            <div className="col-lg-11 align-self-center mx-auto text-center p-lg-0 px-4">
                              <div
                                id="clost-btn"
                                onClick={this.handleClose}
                                className="close-btn exit-intent-close-btn"
                                style={{ top: "-35px" }}
                              >
                                x
                              </div>
                              <div
                                style={{
                                  padding: "30px 60px 0 60px",
                                  background: `linear-gradient(90deg, rgba(138,73,161,0.8) 0%, rgba(138,73,161,0.8) 100%),url(${ModalImg}) `,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center center",
                                  borderRadius: "5px",
                                }}
                              >
                                <h2 className="font-weight-bold text-white mb-3">
                                  Quickbase Integrations Support
                                </h2>
                                {/* <h5 className="mb-3 text-white">test</h5> */}
                                <WebriQForm
                                  id="contact-form"
                                  name="Quickbase Form"
                                  className="contactForm"
                                  data-form-id="10034c81-3cde-4463-bc6f-8df56aa705e0"
                                  data-thankyou-url="/thank-you"
                                >
                                  <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                      <input
                                        type="text"
                                        name="full-name"
                                        size={40}
                                        className="form-control"
                                        placeholder="Full Name"
                                        required
                                      />
                                    </div>

                                    <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                                      <input
                                        type="email"
                                        name="your-email"
                                        size={40}
                                        className="form-control"
                                        placeholder="Email"
                                        required
                                      />
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                                      <textarea
                                        name="message"
                                        rows={5}
                                        cols={0}
                                        className="form-control"
                                        placeholder="Message"
                                      />
                                    </div>
                                    <div className="col-lg-12">
                                      <div className="row mt-3">
                                        <div className="form-group col-lg-12 text-center">
                                          <div className="input-filled">
                                            <button
                                              type="submit"
                                              className="btn btn-primary btn-green w-100"
                                            >
                                              Book your consultation
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group col-12 d-flex justify-content-center captcha-wrap">
                                        <div className="webriq-recaptcha" />
                                      </div>
                                    </div>
                                  </div>
                                </WebriQForm>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

export default FormModal
