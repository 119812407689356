import "bootstrap/dist/css/bootstrap.min.css"
import "font-awesome/css/font-awesome.min.css"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import ExitIntent from "../components/exit-intent/exit-intent-blog"
import modalImg from "../images/new-hero-Background.png"
import "../sass/main.scss"
import ExitIntentMobile from "./exit-intent/exit-intent-blog"
import "./font-face.css"
import Footer from "./footer/footer"
import Header from "./header/header-solution-toprated"
import "./layout.css"

//hooks
import useWindowSize from "./utils/useWindowsSize"

if (typeof window !== "undefined") {
  if (window.fbq != null) {
    window.fbq("track", "PageView")
  }
}

const LayoutSolutionTopRated = ({ children, banner, location }) => {
  const size = useWindowSize()
  useEffect(() => {
    // localStorage.setItem("title", "")
    if (location.pathname !== "/contact") localStorage.removeItem("title")
  })

  return (
    <>
      <Header location={location} />
      {size.width <= 576 ? (
        <ExitIntentMobile
          currentLocation={location}
          btnLink="/contact"
          buttonLabel="Contact Us"
          heading="Not sure if we can fulfill your needs?"
          subHeading="Shoot us a quick message with the challenges you’re facing, and we’ll see how we can help you."
          modalImg={modalImg}
          surveyKey="quickbase"
        />
      ) : (
        <ExitIntent
          currentLocation={location}
          btnLink="/contact"
          buttonLabel="Contact Us"
          heading="Not sure if we can fulfill your needs?"
          subHeading="Shoot us a quick message with the challenges you’re facing, and we’ll see how we can help you."
          modalImg={modalImg}
          surveyKey="quickbase"
        />
      )}
      {banner}
      <main>{children}</main>
      <Footer />
    </>
  )
}

LayoutSolutionTopRated.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutSolutionTopRated
