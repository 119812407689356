import React from "react"
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"

import { StaticImage } from "gatsby-plugin-image"

class Partners extends React.Component {
  render() {
    return (
      <section id="logos-wrapper" className="logos-wrapper">
        <div className="container">
          <div className="row p-3">
            <div className="col-lg-12 mx-auto text-center align-item-center mb-3">
              <div className="row">
                <div className="col-lg-3 col-md-3 mx-auto text-center align-item-center mb-3">
                  <StaticImage
                    placeholder="blurred"
                    className="logo-static-img"
                    imgClassName="img-fluid w-100 h-100"
                    src="../../images/new-images/walgreens-logo.png"
                    alt="Walgreens logo"
                    layout="constrained"
                    width={162}
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-3 col-md-3 mx-auto text-center align-item-center mb-3">
                  <StaticImage
                    placeholder="blurred"
                    className="logo-static-img"
                    imgClassName="img-fluid w-100 h-100"
                    src="../../images/new-images/delta-logo.jpg"
                    alt="Delta logo"
                    layout="constrained"
                    width={162}
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-3 col-md-3 mx-auto text-center align-item-center mb-3">
                  <StaticImage
                    placeholder="blurred"
                    className="logo-static-img"
                    imgClassName="img-fluid w-100 h-100"
                    src="../../images/new-images/testimonials-jacobs.png"
                    alt="Jacobs logo"
                    width={162}
                    // height={130}
                    layout="constrained"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12 mx-auto text-center align-item-center mb-3">
              <div className="row">
                <div className="col-lg-2 col-md-3 mx-auto text-center align-item-center mb-3">
                  <StaticImage
                    placeholder="blurred"
                    className="logo-static-img"
                    imgClassName="img-fluid w-100 h-100"
                    src="../../images/new-images/ain-group.png"
                    alt="AINGroup logo"
                    layout="constrained"
                    width={162}
                    loading="lazy"
                  />
                </div>

                <div className="col-lg-2 col-md-3 mx-auto text-center align-item-center mb-3">
                  <StaticImage
                    placeholder="blurred"
                    className="logo-static-img"
                    imgClassName="img-fluid w-100 h-100"
                    src="../../images/new-images/amneal-logo.png"
                    alt="Amneal logo"
                    layout="constrained"
                    width={162}
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-2 col-md-3 mx-auto text-center align-item-center mb-3">
                  <StaticImage
                    placeholder="blurred"
                    className="logo-static-img"
                    imgClassName="img-fluid w-100 h-100"
                    src="../../images/new-images/testimonials-dexko-global.png"
                    alt="Dexko logo"
                    width={162}
                    layout="constrained"
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-2 col-md-3 text-center align-item-center mb-3">
                  <StaticImage
                    placeholder="blurred"
                    className="logo-static-img"
                    imgClassName="img-fluid w-100 h-100"
                    src="../../images/new-images/kr-wolfe-logo.png"
                    alt="KR WOLFE logo"
                    layout="constrained"
                    loading="lazy"
                  />
                </div>
                <div className="col-lg-2 col-md-3 text-center align-item-center mb-3">
                  <StaticImage
                    placeholder="blurred"
                    className="logo-static-img"
                    imgClassName="img-fluid w-100 h-100"
                    src="../../images/new-images/city-of-la-logo.png"
                    alt="City of LA logo"
                    layout="constrained"
                    width={162}
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Partners
