import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import Modal from "../modal/quickbase-top-rated-modal"

import "./hero.css"

const HeroTopRated = () => {
  // const { heroImage } = useStaticQuery(
  //   graphql`
  //     {
  //       heroImage: file(relativePath: { eq: "new-hero-Background.png" }) {
  //         childImageSharp {
  //           gatsbyImageData(
  //             width: 1920
  //             layout: CONSTRAINED
  //             formats: [AUTO, WEBP, AVIF]
  //           )
  //         }
  //       }
  //     }
  //   `
  // )

  // const heroImageData = getImage(heroImage)

  // // Use like this:
  // const bgImage = convertToBgImage(heroImageData)

  return (
    <>
      <div
        // Tag="div"
        // {...bgImage}
        // fluid={imageData}
        className="hero-banner hero-home hero-quickbase-top-rated"
        // preserveStackingContext
        style={{ backgroundPosition: "top center", height: "600px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="hero-custom-quickbase">
                <p className="h3 page-title font-weight-medium mt-0 mb-4">
                  Concerned About Your Current Partner?
                </p>
                <p className="mb-0" style={{ paddingBottom: "20px" }}>
                  Work with a top-rated service provider who can build the
                  applications your business needs. Delivered on time, within
                  budget, and with a lot less frustration.
                </p>
                <Modal />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HeroTopRated
